<template>
  <div class="list row">
    <div class="col-md-12">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Vorname" v-model="searchFields.forename"
               @keyup.enter="searchByPatientData"/>
        <input type="text" class="form-control" placeholder="Nachname" v-model="searchFields.surname"
               @keyup.enter="searchByPatientData"/>
        <button class="btn btn-outline-secondary"
                @click="searchByPatientData()">
          Suchen
        </button>

        <div class="input-group-append">

          <button class="btn ms-3"
                  :class="{ 'btn-success': showingStatus === this.STATUS_UNPRINTED, 'btn-danger': showingStatus === this.STATUS_ALL }"
                  @click="toggleAllAndPrinted">
            {{ showingStatus ? 'Nicht abgearbeitet' : 'Alle' }}
          </button>
        </div>

      </div>
    </div>
    <div class="col-lg-5 col-sm-6">
      <h1 class="position-relative display-6">
        eRezepte <span class="badge bg-primary">{{ sum }}</span>
      </h1>
      <div class="scroll-container-vertical border rounded-1 p-2">
        <div v-for="(pair, index) in groups"
             :key="index"
             class="mb-3">
          <label class="h6" :for="pair.doctor">
            {{ pair.doctor }}
          </label>
          <ul class="list-group">
            <li class="list-group-item"
                :class="{ active: rezept._id === currentIndex }"
                v-for="(rezept) in pair.rezepts"
                :key="rezept._id"
                @click="setActiveRezept(rezept, rezept._id)"
            >
              <label :for="rezept._id">
                {{ rezept.patient.surname }}, {{ rezept.patient.forename }}: {{ moment(rezept.dateOfIssue, true) }}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-sm-6 mb-3">
      <div v-if="current" class="detail-view">
        <h5>
          {{ current.patient.surname }}, {{ current.patient.forename }}
        </h5>
        <dl class="m-1">
          <dt v-if="current.createdBy">
            Erstellt:
          </dt>
          <dd v-if="current.createdBy">
            {{ moment(current.createdAt) }} von {{ current.createdBy.forename }} {{ current.createdBy.surname }}
          </dd>
          <dt>
            Historie:
          </dt>
          <dd>
            Abgearbeitet: <span class="badge"
                                :class="[ current.status.hasBeenProcessedByPharmacy ? 'bg-success' : 'bg-danger' ]">
              <span v-if="current.status.hasBeenProcessedByPharmacy">Ja</span>
              <span v-else>Nein</span>
            </span>
            <div v-for="printHistory in current.status.history" :key="printHistory.id">
              <span class="badge bg-primary">{{ moment(printHistory.date) }}</span>
              {{ printHistory.user.forename }} {{ printHistory.user.surname }}:
              {{ printHistory.action }}
            </div>
          </dd>

          <dt>Ausstellungsdatum:</dt>
          <dd>{{ moment(current.dateOfIssue, true) }}</dd>

          <dt>Geburtsdatum:</dt>
          <dd>{{ moment(current.patient.dateOfBirth).substring(0, 10) }}</dd>

          <dt v-if="current.medications">Arzneien:</dt>
          <dd v-for="(medication, index) in current.medications" :key="index + medication.pzn">
            <p class="mt-2">
              {{ medication.menge }}&times; <b>{{ medication.name }}</b> <br>
              {{ medication.dosierung }} <br>
              {{ medication.packung }} <br>
              {{ medication.tageszeit }} <br>
              <b>PZN:</b> {{ medication.pzn.toString().padStart(8, '0') }}
            </p>
          </dd>

          <dt v-if="current.fullText">
            Gesamter Rezepttext:
            <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFulltext" aria-expanded="false" aria-controls="collapseFulltext">
              Anzeigen
            </button>
          </dt>
          <dd class="collapse" style="white-space: pre-wrap;" id="collapseFulltext">
            {{ current.fullText }}
          </dd>

          <dt>eRezept-Link:</dt>
          <dd>
            <div class="input-group">
              <input :value="current.codes.collectiveCode.embeddedLink"
                     @click="$event.target.select(); copy(current.codes.collectiveCode.embeddedLink)"
                     class="w-100" />
            </div>
            <br>
            <button class="btn btn-primary text-white"
                    @click="copyAndMarkProcessed(current.codes.collectiveCode.embeddedLink)">
              Kopieren und als abgearbeitet markieren
              <span v-if="copiedAndProcessed">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
            </span>
            </button>

            <br>

            <button class="btn btn-outline-success mt-3"
                    @click="copy(current.codes.collectiveCode.embeddedLink)">
              Nur kopieren
              <span v-if="copied">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
            </span>
            </button>
          </dd>

          <dt class="mt-2">DataMatrix-Code:</dt>
          <dd>
            <img
                v-bind:src="'data:'+
                current.codes.collectiveCode.contentType+
                ';base64,'+current.codes.collectiveCode.data"
                alt="DataMatrix-Code"
                style="width: 200px;"
            />
          </dd>
        </dl>

        <button class="btn btn-danger" @click="deleteRezept">
          eRezept löschen
        </button>
        <br>
        <button v-if="current.status.hasBeenProcessedByPharmacy"
                class="btn btn-warning mt-3" @click="markRezeptUnprocessed">
          eRezept als nicht abgearbeitet markieren
        </button>
        <button v-else class="btn btn-success mt-3" @click="markRezeptProcessed">
          eRezept als abgearbeitet markieren
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RezeptService from "../services/rezept.service";
import AuthService from "../services/auth.service";
import moment from "moment";
import _ from "lodash";

export default {
  name: "rezepts-list",

  data() {
    return {
      STATUS_ALL: 0,
      STATUS_UNPRINTED: 1,
      selected: [],
      groups: [],
      sum: 0,
      current: null,
      showingStatus: this.STATUS_UNPRINTED,
      currentIndex: -1,
      searchFields: {
        forename: "",
        surname: "",
      },
      errorMessage: "",
      normalMessage: "",
      copied: false,
      copiedAndProcessed: false,
    };
  },

  computed: {
    selectAll: {
      get: function () {
        return this.sum ? this.selected.length === this.sum : false
      },

      set: function (check) {
        this.clearMessages()
        if (check) {
          this.groups.forEach(mandant => {
            mandant.rezepts.forEach(rezept => {
              this.selected.push(rezept._id)
            })
          })
        } else {
          this.selected = []
        }
      }
    },

    isRezeptProcessor() {
      return AuthService.hasRole(this.$store.state.auth.user, "eRezeptProcessor")
    },
  },

  methods: {
    init() {
      this.getUnprocessedRezept()
    },

    clearMessages() {
      this.errorMessage = ""
      this.normalMessage = ""
    },

    moment(date, short=false) {
      return short ? moment(date).format("DD.MM.YYYY") : moment(date).format("DD.MM.YYYY HH:mm:ss")
    },

    isAllRezeptsInSelected(rezepts) {
      console.log(rezepts)
      return rezepts?.every(el => this.selected.includes(el._id))
    },

    selectAllDoctor(rezepts, check) {
      this.clearMessages()
      if (check) {
        rezepts.forEach(rezept => {
          this.selected.push(rezept._id)
        })
      } else {
        rezepts = rezepts.map(el => el._id)
        this.selected.filter(el => {
          return !rezepts.includes(el._id)
        })
        this.selected = this.selected.filter(el => !rezepts.includes(el))
      }
    },

    updatePrintJob(rezeptId, checked) {
      this.clearMessages()
      if (checked) {
        // enable permission
        this.selected.push(rezeptId)
      } else {
        this.selected = this.selected.filter(
            el => el !== rezeptId
        )
      }
    },

    printSelectedRezepts() {
      this.clearMessages()
      RezeptService.printSelected(this.selected)
          .then(response => {
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'eRezepte.pdf');
            document.body.appendChild(link);
            link.click();

            // reset the view
            this.selected = []
            this.getUnprocessedRezept()
          })
          .catch((error) => {
            console.log(error.response.data.text().then(text => {
              this.errorMessage = JSON.parse(text).message
            }))

          })
    },

    loadRezepts(data) {
      this.sum = data.sum
      this.groups = _.chain(data.rezepts)
          .groupBy("doctor.name")
          .map((value, key) => ({ doctor: key, rezepts: value }))
          .value()

      this.setActiveRezept(null)
    },

    getUnprocessedRezept() {
      this.showingStatus = this.STATUS_UNPRINTED

      RezeptService.getAllUnprocessed()
          .then(response => {
            this.loadRezepts(response.data)
          })
          .catch(e => {
            this.errorMessage = e.response.data.message
          });
    },

    getRezepts() {
      this.showingStatus = this.STATUS_ALL

      RezeptService.getAll()
          .then(response => {
            this.loadRezepts(response.data)
          })
          .catch(e => {
            this.errorMessage = e.response.data.message
          });
    },

    toggleAllAndPrinted() {
      if (this.showingStatus === this.STATUS_ALL) this.getUnprocessedRezept()
      else this.getRezepts()
    },

    deleteRezept() {
      RezeptService.delete(this.current._id)
          .then(response => {
            this.refreshList()
            this.normalMessage = response.data.message;
          })
          .catch(e => {
            this.errorMessage = e.response.data.message;
          })
    },

    markRezeptUnprocessed() {
      RezeptService.markUnprocessed(this.current._id)
          .then(response => {
            this.refreshList()
            this.normalMessage = response.data.message
          })
          .catch(reason => {
            this.errorMessage = reason.response.data.message
          })
    },

    markRezeptProcessed() {
      RezeptService.markProcessed(this.current._id)
          .then(response => {
            this.refreshList()
            this.normalMessage = response.data.message
          })
          .catch(reason => {
            this.errorMessage = reason.response.data.message
          })
    },

    searchByPatientData() {
      RezeptService.findByPatientData(this.searchFields.forename, this.searchFields.surname, this.showingStatus === this.STATUS_UNPRINTED)
          .then(response => {
            this.loadRezepts(response.data)
          })
          .catch(e => {
            this.errorMessage = e.response.data.message
          });
    },

    refreshList() {
      this.getUnprocessedRezept();
      this.current = null;
      this.currentIndex = -1;
      this.normalMessage = "";
      this.errorMessage = "";
    },

    setActiveRezept(rezept, index) {
      if (index === this.currentIndex) {
        this.setActiveRezept(null)
      } else {
        this.current = rezept;
        this.currentIndex = rezept ? index : -1;
      }
    },

    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },

    copy(text) {
      this.copied = true;
      this.copyTextToClipboard(text)
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },

    copyAndMarkProcessed(text) {
      this.copiedAndProcessed = true;
      this.copyTextToClipboard(text)
      this.markRezeptProcessed()
      setTimeout(() => {
        this.copiedAndProcessed = false;
      }, 2000);
    },

    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    }

  },

  mounted() {
    this.init()
  }
};
</script>

<style scoped>
.list {
  text-align: left;
  max-width: 70vw;
  margin: auto;
}

li {
  cursor: pointer;
}

.scroll-container-vertical {
  max-height: 78vh;
  overflow: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch
}

.detail-view {
  margin-top: 10px;
}

@media only screen and (max-width: 991px) {
  .scroll-container-vertical {
    max-height: 30vh;
  }

  .detail-view {
    margin-top: 0;
  }
}
</style>
